
import wx from 'wx';
import {
	handlTurnToWxSignature
} from '@index/api/wx';
import {
	handleQuerySubDomainInfo
} from '@index/api/login';
import router from '../../router';
export default {
	name: "mobileLogin",
	components: {

	},
	data() {
		return {
			currIndex: 'one',
			domain: { schemeName: '' },
			//username: 'gh_e3e733799142', // 小程序的原始id
			//path: 'pages/login/login?aa=1', // 要打开的页面,
			wxConfig: {},
		};
	},
	created() {		
		document.title = "";
		//this.querySubDomainInfo(); //获取子域名信息	
		//this.turnToWxSignature();
	},
	mounted() {
		this.querySubDomainInfo(); //获取子域名信息	
	},
	watch: {
		wxConfig() {
			//this.configWxParam();
		}
	},
	methods: {
		turnToWxSignature() { //跳转到微信小程序的验签
			handlTurnToWxSignature().then(res => {
				this.wxConfig = res.data;
			});
		},
		openMiniProgram() {
			// ...
		},
		configWxParam() {
			var _this = this;
			wx.config({
				debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: _this.wxConfig.appId, // 必填，公众号的唯一标识
				timestamp: _this.wxConfig.timestamp, // 必填，生成签名的时间戳
				nonceStr: _this.wxConfig.nonceStr, // 必填，生成签名的随机串
				signature: _this.wxConfig.signature, // 必填，签名
				jsApiList: [
					'onMenuShareTimeline',
					'onMenuShareAppMessage',
					'updateAppMessageShareData',
					'updateTimelineShareData'
				],
				openTagList: ['wx-open-launch-weapp']
			});

		},
		async querySubDomainInfo() { //查询子域名	
			var hrefStr = location.href.toLowerCase(); // location.href;	https://jfzsoft.com/mobile/login
			var sub = "";
			hrefStr = hrefStr.replace(/https:\/\/|http:\/\/|www./g, '');
			sub = hrefStr.substring(0, hrefStr.indexOf('.'));		
			if (sub && sub.toLocaleLowerCase() != 'bm') {
				const res = await handleQuerySubDomainInfo(sub);
				if (res.status == 200&&res.data) {
					this.domain = res.data;
					if (this.domain != null) {
						document.title = res.data.schemeName;
						return;
					}
				}
				
			}			
			this.domain = {
				appletLogo: 'https://score2021.oss-cn-beijing.aliyuncs.com/scorer-logo/d0a03367726c4ffcaac4aa05459f17fb69df31f3150b4d8f9b453c4f7db74764.png',
				schemeName: '我是记分长',
				signupQRCode: 'https://score2024.oss-cn-beijing.aliyuncs.com/qrcode/01f88f43acca4c96a7ed6f5230326c9b.png',
				queryQRCode: 'https://score2024.oss-cn-beijing.aliyuncs.com/qrcode/a7c760f6869f42108de670baef5bccf1.png'
			}
			document.title = "我是记分长";
		},
		selectType(type) {
			this.currIndex = type;
		}

	}
};